import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/Seo';
import ThreeColCta from '../../../../components/ThreeColCta';

const HorizonApp = () => (
	<Layout>
		<Seo
			title="Download myPhonak App | hear.com"
			description="Dowload the myPhonak hearing aids app by clicking here. Stay always connected with Phonak hearing aids app."
		/>
		<div className="o-wrapper">
			<div className="o-row-2p5">
				<div className="c-newsletter-img">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-hearing-aids-app.png"
						alt="man sitting on couch and smiling"
						loading="lazy"
					/>
				</div>
			</div>
			<div className="o-row-2p5">
				<h1 className="c-content-hero__headline">Download myPhonak App</h1>
			</div>
			<ul className="c-usps">
				<li>Enhanced hearing controls and personalization options </li>
				<li>Improved ease of use and security </li>
				<li>Captures health data and monitors your hearing aid usage</li>
			</ul>
			<div className="c-app-box">
				<a
					className="c-app-box__panel c-app-box__android"
					href="https://play.google.com/store/apps/details?id=com.sonova.phonak.dsapp"
				>
					<p className="c-app-box__android-title">Android</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/android-small.png"
						alt=""
					/>
				</a>
				<a className="c-app-box__panel c-app-box__ios" href="https://apps.apple.com/us/app/myphonak/id1332662924">
					<p className="c-app-box__ios-title">iOS</p>
					<img
						className="c-app-img o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/assets/na/seo-website/ios-small.png"
						alt=""
					/>
				</a>
			</div>
			<section className="c-app-section">
				<div className="o-row-2p5">
					<p className="c-app-section__title">myPhonak app</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						Experience a new level of personalized hearing and health tracking with the redesigned myPhonak app for{' '}
						<a href="https://www.hear.com/hearing-aids/brands/phonak/">Phonak hearing aids</a>. Seamlessly tailored to
						your unique needs, myPhonak offers enhanced functionality and a sleek new design. With myPhonak, you gain
						access to advanced hearing controls and customization options for your Phonak hearing aids. Easily adjust
						settings such as volume, noise reduction, and microphone directionality to optimize your listening
						experience in any environment. Choose from preset programs or fine-tune sound quality with customizable
						equalizer settings, including bass, middle, and treble frequencies.
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						The Remote Control feature puts you in command, allowing quick adjustments to your hearing aids for
						different listening situations. Need assistance? With Remote Support, connect with your hearing care
						professional via live video call for remote adjustments (by appointment).
					</p>
				</div>
				<div className="o-row-2p5">
					<p className="c-app-section__copy">
						But myPhonak goes beyond just hearing. In the Health section, track your activity levels, steps*, and
						wearing time* to stay on top of your well-being. Set personal goals*, monitor heart rate (available on
						select models), and keep tabs on distance walked and ran. Upgrade your hearing experience and take control
						of your health with myPhonak—the ultimate all-in-one solution for personalized hearing and wellness
						tracking.
					</p>
				</div>
				<div className="o-row-2p5">
					<ul className="c-app-section__list">
						<li>*Available on Paradise Rechargeable, Audéo Fit, and Lumity devices.</li>
						<li>**Heart rate tracking available on Audéo Fit only.</li>
						<li>***Distance tracking available on Audéo Fit and Lumity devices.</li>
					</ul>
				</div>
			</section>
			<div className="o-row-2p5">
				<p className="c-app-section__title">Resources</p>
			</div>
			<div className="o-row-2p5">
				<ThreeColCta
					ImgSrc1="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg"
					ContentTitle1="Hearing Test"
					ContentCopy1="Check how is your hearing in less than 5 minutes."
					Url1="/hearing-test/"
					ImgSrc2="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg"
					ContentTitle2="Hearing Loss"
					ContentCopy2="There are several types of hearing loss and many different cause."
					Url2="/hearing-loss/"
					ImgSrc3="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg"
					ContentTitle3="Hearing Aids"
					ContentCopy3="Connect you to the world around you with our wide range of devices."
					Url3="/hearing-aids/"
				/>
			</div>
		</div>
	</Layout>
);

export default HorizonApp;
